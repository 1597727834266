<template>
  <div class="noticeMore">
    <!-- 备忘录更多页面 -->
    <Header
      title="备忘录"
      back="返回"
      index="首页"
      beforeTitle="备忘录"
      work_bench="工作台"
    />
    <div class="memo">
      <h1>备忘录</h1>
      <ul v-loading="loading" element-loading-text="正在加载中···">
        <li v-for="(item, index) in memolist.data" :key="index">
          <img
            src="../../assets/images/roundblackjiantou.png"
            alt=""
            width="18px"
            height="18px"
            style="margin-left: 20px"
          /><span>{{ item.create_time }}</span>
          <img
            src="../../assets/images/bluesmalljiantou.png"
            alt=""
            width="10px"
            height="8px"
          />
          <div @click="goDetail(item)" class="moreText">
            {{ item.content }}
          </div>
          <el-row class="icon-more">
            <el-col :span="12">
              <el-dropdown trigger="hover">
                <span class="el-dropdown-link">
                  <i class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    ><span style="color: #0f38ff" @click="infoMmemo(item)"
                      >编辑</span
                    ></el-dropdown-item
                  >
                  <el-dropdown-item
                    ><span style="color: #ff2020" @click="delMemo(item.id)"
                      >删除</span
                    >
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>
          </el-row>
        </li>
      </ul>
      <div class="count">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="listParams.page"
          :page-sizes="[2, 10, 20, 40]"
          :page-size="listParams.limit"
          layout="sizes, prev, pager, next,total,jumper"
          :total="memolist.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      loading: true,
      listParams: {
        page: 1,
        limit: 10,
      },
    };
  },
  created() {
    this.$store.dispatch("getmemolist", this.listParams);
  },
  updated() {
    this.loading = false;
  },
  methods: {
    infoMmemo(item) {
      this.$router.push({ path: "/memo/memoAdd", query: { item: item } });
      // console.log(item, "item");
    },
    delMemo(id) {
      console.log(id);
      this.$confirm("确定删除此项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.axios.delete("/api/memorandum/delete", {
            params: {
              id: id,
            },
          });
          if (!id) {
            this.$message({
              type: "error",
              message: "删除失败!",
            });
          } else {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
          this.$store.dispatch("getmemolist", this.listParams);
        })
        .catch((res) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSizeChange(val) {
      this.listParams.limit = val;
      this.$store.dispatch("getmemolist", this.listParams);
      // console.log(val, "val");
    },
    handleCurrentChange(val) {
      this.listParams.page = val;
      this.$store.dispatch("getmemolist", this.listParams);
      // console.log(val, "当前页");
    },
    // 跳转详情页
    goDetail(item) {
      this.$router.push({ path: "/memo/memoDetail", query: { id: item.id } });
    },
  },
  computed: {
    ...mapState(["memolist"]),
  },
};
</script>

<style scoped>
.noticeMore {
  height: 100%;
}
.memo {
  background: #fff;
  width: 95%;
  height: 100%;
  padding: 40px;
  margin-top: 15px;
  position: relative;
}
.memo h1 {
  font-size: 18px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  color: #000000;
}
.memo ul {
  margin-top: 14px;
}
.memo ul li {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  line-height: 30px;
  margin: 15px 0;
}
.memo ul li:hover {
  background: #f2f3f9;
}
.memo ul li .moreText {
  cursor: pointer;
  width: 60%;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.memo ul li span {
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  color: #000000;
  margin-right: 25px;
}
.memo ul li img {
  margin-right: 25px;
}
.memo .count {
  margin-top: 20px;
}
.el-icon-more {
  font-size: 20px;
  z-index: 99;
  cursor: pointer;
}
.icon-more {
  position: absolute;
  right: 35px;
}
.icon-more h3 {
  margin: 5px auto;
  cursor: pointer;
}
.memobut {
  margin-left: 15px;
}
</style>
